.depositFunds_box {
  .form_credit {
    margin-top: 50px;

    .purple {
      color: $primary-purple !important;
    }

    /deep/ .ccRow {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      column-gap: 40px;

      /deep/ .el-form-item {
        margin-bottom: 60px;
      }
    }

    .form_content {
      position: relative;
      border: $container-border;
      border-radius: $container-radius;
      padding: 50px;

      > .fr {
        @include rtl-sass-prop-sd(left, 0);
        @include rtl-sass-prop-sd(right, unset);
        position: absolute;
        width: 25%;
        top: calc(25% - 100px);
        right: 0;
        text-align: center;

        .logo {
          max-width: 150px;
          margin-bottom: 38px;
        }

        p.card_tips {
          line-height: 1.64;
          text-align: right;
          color: $primary-purple;
          margin-top: 20px;
        }
      }

      > .fl {
        @include rtl-sass-value(float, left, right);
        width: 70%;

        .row {
          display: grid;
          grid-template-columns: 45% 45%;
          column-gap: calc((100% - 90%) / 2);

          /deep/ .el-form-item {
            margin-bottom: 60px;
          }

          /deep/ .ccMultipleColumn,
          /deep/ .dateMultipleColumn {
            position: relative;
            .labelTitle {
              position: absolute;
            }
            .row {
              display: grid;

              .el-form-item {
                margin-bottom: 0;
              }
            }
          }

          /deep/ .ccMultipleColumn {
            .labelTitle {
              top: -28px;
            }
            .row {
              grid-template-columns: 31% 31% 31%;
            }
          }

          /deep/ .dateMultipleColumn {
            .labelTitle {
              top: -14px;
            }
            .row {
              grid-template-columns: 48% 48%;
            }
          }

          /deep/ .ccv {
            margin-bottom: 20px;

            .el-form-item {
              margin-bottom: 25px;
            }

            .tips {
              font-size: 12px;
              font-weight: 200;
              color: $primary-purple;
            }
          }
        }
      }
      /deep/ .row + .el-form-item__error {
        display: none;
      }
    }
  }

  .form_button {
    margin-top: 20px;
    @include rtl-sass-value(text-align, left, right);
  }
}

@media (max-width: 812px) {
  .depositFunds_box .form_credit {
    .form_content > .fl .row {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      /deep/ .dateMultipleColumn .row .el-form-item {
        margin-bottom: 60px;
      }
    }
  }
}

@media (max-width: 768px) {
  .depositFunds_box .form_credit {
    margin-top: 40px;

    .form_content {
      > .fr,
      > .fl {
        width: 100%;
      }

      > .fr {
        position: relative;
        text-align: center;
        margin-bottom: 40px;

        .logo {
          display: block;
          margin: 0 auto 10px;
        }

        p.card_tips {
          text-align: center;
        }
      }
    }
  }

  .depositFunds_box .form_button {
    text-align: center;
    @include rtl-sass-prop(margin-left, margin-right, 0);
  }
}

@media (max-width: 550px) {
  .depositFunds_box .form_credit {
    margin-top: 8px;

    /deep/ .ccRow {
      display: grid;
      grid-template-rows: 1fr;

      /deep/ .el-form-item {
        margin-bottom: 60px;
      }
    }

    .form_content {
      > .fr {
        @include rtl-sass-value(text-align, left, right);

        .logo {
          margin: 0 0 10px;
        }

        p.card_tips {
          @include rtl-sass-value(text-align, left, right);
        }
      }

      > .fl .row /deep/ {
        .dateMultipleColumn .row .el-form-item {
          margin-bottom: 60px;
        }
        .ccv {
          .tips {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.mb_10 {
  margin-bottom: 10px;
}
