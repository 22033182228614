
              @import "@/assets/css/variables/variables.scss";
            

.container {
  display: inline;
}
.purple_button {
  background-color: #ecf5ff;
  color: $primary-purple;
}
