
              @import "@/assets/css/variables/variables.scss";
            

@import '@/assets/css/pages/deposit/creditOrDebit.scss';
@import '@/assets/css/pages/deposit/default.scss';

.box_card_top {
  box-shadow: unset;
  padding: 0 0 20px;
}
