
              @import "@/assets/css/variables/variables.scss";
            

@import '@/assets/css/components/deposit/multiButton.scss';

.purple_button {
  border-radius: 16px;
  border: 2px solid rgba(60, 67, 155, 0.1);
}
.white_button {
  border-radius: 16px;
  border: 2px solid #3c439b;
  background-color: #ffffff;
  color: $primary-purple;
}
.purple_button,
.white_button {
  width: 140px;
  &:focus {
    background-color: $primary-purple;
    color: #fff;
  }
  &:hover,
  &:active {
    border: 2px solid #3c439b;
    background-color: rgba(60, 67, 155, 0.15);
    color: $primary-purple;
  }
}
