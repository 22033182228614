.form {
  h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.42;
    color: $primary-purple;
    margin-bottom: 20px;
  }
}

.form_success {
  padding: 40px 0 43px;
  background-color: #fff;
  box-shadow: $box-shadow;

  .form_success_content {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    text-align: center;

    .show {
      > img {
        width: 114px;
        margin-bottom: 40px;
      }
    }

    .upload ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      li.fl {
        @include rtl-sass-prop(margin-right, margin-left, 30px);
      }
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      color: $primary-purple;
      margin-bottom: 30px;

      &.last {
        margin-bottom: 100px;
      }

      a {
        color: #a2d4ff;
        text-decoration: underline;
        word-wrap: break-word;
      }
    }
  }
}

@media (max-width: 600px) {
  .form_success {
    padding: 50px 20px;

    .form_success_content {
      .upload ul {
        display: inline;
        li.fl {
          @include rtl-sass-prop(margin-right, margin-left, 0);
        }

        .fl,
        .fr {
          float: none;
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 550px) {
  .form {
    padding: 24px;

    h4 {
      margin-bottom: 0;
    }
  }
  .form_success {
    padding: 24px;

    .form_success_content {
      .show > img {
        width: 61px;
      }

      p,
      p.last {
        margin-bottom: 40px;
      }
    }
  }
}
